import React, { Component } from "react";
import { connect } from "react-redux";
import data from '../json/data.json'
import { StoragesSystem } from "../../../@main-module/actions-manager/actions-data";
import { withRouter } from "../../../@hoc/with-router";
import { RenderNodes } from "../../../@render-node";

import { InputBox } from "../../../@component/form/input-box/input-box";
import { ButtonActionsGlobal } from "../../../@component/form/buttons/buttons-actions-global";
import { ButtonRender } from "../../../@component/form/buttons/buttons-render";

const PageDefaultProps = {
  StoragesSystem: {
    catalog: [],
  },
};

class SharePage extends Component {
  static defaultProps = PageDefaultProps;
  constructor(props) {
    super(props);
    this.state = {
      tamu: 'Nama'
    };
  }
  timeOut = null;

  componentDidUpdate = (prevProps) => {

  }

  jsEncodeURi = (str = "") => {
    const arr = str.split(" ");
    return arr.join("-")
  }

  share = () => {
    const message = `
Kepada Yth. 
Bapak/Ibu/Saudara/i

*${this.state.tamu}*
__________

Assalamu’alaikum Wr. Wb.

*Bismillahirahmanirrahim*.
Tanpa mengurangi rasa hormat, perkenankan kami mengundang Bapak/Ibu/Saudara/i, teman sekaligus sahabat, untuk menghadiri acara pernikahan kami :

*${data.women.fullname}*
  *&*
*${data.man.fullname}*

Berikut link untuk info lengkap undangan kami :

https://${window.location.host}/?tamu=${this.jsEncodeURi(this.state.tamu)}


*(Salin link dan buka di browser apabila link tidak dapat dibuka)*


Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan untuk hadir dan memberikan doa restu.

*Mohon maaf perihal undangan kami bagikan melalui pesan ini*.

Terimakasih banyak atas perhatiannya.

*Wassalamu’alaikum Wr. Wb.*


Hormat kami,
*${data.women.nickname} 💞 ${data.man.nickname}*
    `;
    return message
  }

  shareWA = () => {
    const el = document.createElement("a");
    el.setAttribute("href", `whatsapp://send?text=${`${encodeURIComponent(this.share())}`}`)
    el.setAttribute("target", "_blank")
    el.click()
  }

  shareFacebook = () => {
    const el = document.createElement("a");
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`https://${window.location.host}/?tamu=${this.jsEncodeURi(this.state.tamu)}`)}`;
    el.setAttribute("href", facebookShareUrl)
    el.setAttribute("target", "_blank")
    el.click()
    // const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.share()}`;
    // window.open(facebookShareUrl, '_blank', 'width=600,height=400');
  }

  shareAll = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `${data.women.nickname} 💞 ${data.man.nickname} Wedding`,
          text: this.share(),
          // url: `https://${window.location.host}/?tamu=${this.jsEncodeURi(this.state.tamu)}`,
        })
        .then(() => console.log('Content shared successfully'))
        .catch((error) => console.error('Error sharing content:', error));
    } else {
      alert('Sharing is not supported in this browser.');
    }
  }

  onChange = ({ value }) => {
    this.setState({ tamu: value })
  }
  render() {
    const { props, state } = this;
    return (
      <section className="share_page preloader-plus">
        <div className="share_inside">
          <InputBox title={"Masukan Nama"} onChange={this.onChange} />
          <ButtonActionsGlobal onClick={this.shareWA} render={() => (
            <ButtonRender >
              Share To Whatsapp
            </ButtonRender>
          )} />
          <ButtonActionsGlobal onClick={this.shareFacebook} render={() => (
            <ButtonRender >
              Share to Facebook
            </ButtonRender>
          )} />
          <ButtonActionsGlobal onClick={this.shareAll} render={() => (
            <ButtonRender >
              Share to Phone
            </ButtonRender>
          )} />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  StoragesSystem: state.StoragesSystem,
});
const mapDispatchToProps = (dispatch) => ({
  setStorage: (data = { data: null, type: 'yourkey' }) =>
    dispatch(new StoragesSystem().setStorage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SharePage, {
  isRestriected: true,
}));
