import React, { Component } from "react";
import './index.scss'
import { InputBox } from "../../@component/form/input-box/input-box";
import { ButtonActionsGlobal } from "../../@component/form/buttons/buttons-actions-global";
import { ButtonRender } from "../../@component/form/buttons/buttons-render";
import { OwnIcon } from "../../@component/icons/module-icons";
import { ServerRequest } from "../../@utils/utils-server-request";
import { BoxLoader } from "../../@component/loader/box-loader";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { GenerateAtribute } from "../../@utils/generate-atribute";
dayjs.extend(relativeTime);

const DOMAIN_API = `https://ulamulemdb.ulamulem.com/api`

export class Comments extends Component {
  static defaultProps = {
    dismiss: null,
    show: null
  };
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      message: "",
      data: props.dataComments || []
    }
  }
  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    return res
  }

  setValueToState = ({ value, isError }, key) => {
    this.setState({
      [key]: value
    })
  }

  isReady = () => {
    if (this.state.username && this.state.message) {
      return true
    }
    return false
  }

  getTime = (value) => {
    if (value) {
      return dayjs(value)?.fromNow?.(true)
    }
    return `Baru saja`
    const dateNow = new Date();
    const targetDate = new Date(value)
    const diffDays = (dateNow - targetDate) / (1000 * 60 * 60 * 24);
    if (diffDays < 1) {
      return `Baru saja`
    }
    return `${parseInt(diffDays)} jam yang lalu`
  }

  getData = async () => {
    this.setState({ isFound: false, isFetching: false, isError: false, data: [] }, () => {
      new ServerRequest(DOMAIN_API).get({ url: '/get/' + window.location.hostname }, (e = {}) => {
        if (e?.isFound) {
          const sorted = this.sortingData(e.data?.data || [])
          this.setState({
            ...e,
            data: sorted,
          })
        } else {
          this.setState({
            ...e,
          })
        }
      })
    })
  }

  onSubmitButton = () => {
    if (this.isReady()) {
      this.setState({}, () => {
        let data = JSON.parse(JSON.stringify(this.state.data));
        const body = {
          username: this.state.username,
          message: this.state.message,
          date: new Date().getTime()
        }
        data = [
          body,
          ...data
        ]
        this.setState({ data: data, username: '', message: '' }, () => {
          new ServerRequest(DOMAIN_API).post({ url: '/post/' + window.location.hostname, body }, (e) => {
            if (e?.isFound) {
              console.log("success")
            } else {

            }
          })
        })
      })
    }
  }

  _timer = null;
  componentDidMount = () => {
    clearTimeout(this._timer)
    this._timer = setTimeout(this.getData)
  }

  gogglesign = async () => {
    // const auth = new google.auth.GoogleAuth({
    //   credentials: {
    //     ...credential
    //   },
    //   scopes: "https://www.googleapis.com/auth/spreadsheets",
    // })
    // const client = await auth.getClient();
    // const googleSheets = google.sheets({ version: "v4", auth: client });

    // const spreadsheetId = this.props.databaseId;
    // callback({
    //   auth,
    //   spreadsheetId,
    //   googleSheets
    // })
  }
  sortingData = (arr) => {
    let temp = [];
    for (let i = 0; i < arr.length; i++) {
      temp = [
        arr[i],
        ...temp
      ]
    }
    return temp
  }




  render() {
    const { props, state } = this;
    const elementProps = this.getElementConfig(props);
    return (
      <section {...elementProps} style={{ width: '100%', ...elementProps.style }} className={`fox_comments ${props.className} ${elementProps.className}`} >
        <div className="comment_wrapper">

          <div className="comments_form">
            <InputBox title={"NAMA ANDA"} value={state.username} onChange={(e) => this.setValueToState(e, "username")} />
            <InputBox title={"UCAPAN"} value={state.message} onChange={(e) => this.setValueToState(e, "message")} isMultiline={true} />
            <div className="action_button">
              <ButtonActionsGlobal onClick={this.onSubmitButton} render={() => (
                <ButtonRender>
                  KIRIM
                </ButtonRender>
              )} />
            </div>
          </div>
          <div className="comment_list">
            <div className="comment_list_inside">
              <BoxLoader isShow={state.isFetching} />
              {!state.isFetching && state.data?.map((item, i) => (
                <div className="comment_content" key={i}>
                  <div className="comment_info">
                    <OwnIcon type="user" />
                    <div className="right_path">
                      <a className="commenter_name" >{item.username}</a>
                      <div className="comment_text">
                        <p>{item.message}</p>
                      </div>
                    </div>

                  </div>

                  <div className="comment_actions">
                    <span className="comment_time">
                      {this.getTime(item.date)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
