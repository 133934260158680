import React, { Component } from "react";
// import PhotoAlbum from "react-photo-album";
import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";
import { OwnIcon } from "../../@component/icons/module-icons";

export class Galery extends Component {
  static defaultProps = {
    dismiss: null,
    show: null
  };
  constructor(props) {
    super(props);
    this.state = {
      sizecolumn: 2,
      isLoad: true,
      windowSize: 0,
      activeImage: null
    }
  }
  getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    return res
  }

  componentDidMount = () => {
    this.init()
  }

  componentWillUnmount = () => {
    clearInterval(this._timerCount)
  }

  _timerCount = null
  init = () => {
    clearInterval(this._timerCount)
    this._timerCount = setInterval(this.getSize, 1000)
  }

  getSize = () => {
    let sizecolumn = 3;
    if (window.innerWidth <= 768 && window.innerWidth > 520) {
      sizecolumn = 3
    } else if (window.innerWidth <= 520) {
      sizecolumn = 2
    } else if (window.innerWidth > 768) {
      if (!this.props.media.ismobile) {
        // sizecolumn =  5
      }
    }
    if (window.innerWidth != this.state.windowSize) {
      this.setState({ sizecolumn, isLoad: true, windowSize: window.innerWidth }, () => {
        setTimeout(() => {
          this.setState({ isLoad: false })
        }, 1000)
      });
    }
  }

  getPhoto = (photos, sizecolumn) => {
    const res = [];
    for (let i = 0; i < photos.length; i++) {
      const item = photos[i];
      if (sizecolumn % 2 == 0) {
        if (!item.isSkip) {
          res.push(item)
        }
      } else {
        res.push(item)
      }
      // if(item.isSkip && sizecolumn % 2 == 0){
      //   res.push(item)
      // }else{
      //   res.push(item)
      // }
    }
    return res
  }

  getPhotoSpited = (photos, numGroups) => {
    // const res = [];
    // const dataLength = photos.length / 3;
    // for (let j = 0; j < dataLength; j++) {
    //   const element = array[i];

    // }
    // for (let i = 0; i < photos.length; i++) {
    //   const child = []
    //   const item = photos[i];
    //   if(!item.isSkip){
    //     res.push(item)
    //   }
    // }
    // return res

    const result = Array.from({ length: numGroups }, () => []);

    photos.forEach((item, index) => {
      result[index % numGroups].push(item);
    });

    return result;
  }

  onOpen = (img) => () => {
    this.setState({ activeImage: img })
  }

  render() {
    const { props, state } = this;
    const photos = props.galery || [];
    const getWidth = { width: `${100 / state.sizecolumn}%` }
    return (
      <section {...this.getElementConfig(props)} style={{ ...this.getElementConfig(props).style, width: '100%', }} className={`fox-galery ${props.className}`} >
        {/* <Gallery photos={photos} />; */}
        {/* { !state.isLoad &&
          <PhotoAlbum layout="columns" photos={this.getPhoto(photos)} columns={state.sizecolumn} />
        } */}
        <div className="box_gallery">
          {this.getPhotoSpited(this.getPhoto(photos, state.sizecolumn), state.sizecolumn).map((groups, id) => (
            <div key={id} className="group_colum" style={getWidth}>
              {groups.map((img, idImg) => (
                <div className="img_galery animated" key={idImg} onClick={this.onOpen(img.src)}>
                  <img src={img.src} />
                </div>
              ))}
            </div>
          ))}
        </div>
        {state.activeImage && (
          <div className="zoom_posizion">
            <div className="zoom_box">
              <div className="button_close" onClick={this.onOpen(null)}><OwnIcon type="close" /></div>
              <div className="zoom_container">
                <img src={state.activeImage} />
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}
