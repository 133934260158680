import React, { Component } from "react";
import { Nodes } from "../@nodes";
import { PlayMusciButton } from "../@nodes/play-music-button";
import { NavigationBar } from "../@nodes/navigation-bar";
import { WhatsappTheme } from "../@utils/whatsaap-message-themplate";
import { WA_NUMBER } from "../@utils/constant";


class NodesLoopRender extends Component {
  render() {
    const { props } = this;
    const nodeInfo = { ...props, ...props.sections[props.id], data: {} }
    if (nodeInfo.media?.["fox-section"]) {
      if (nodeInfo.node.length < 1) {
        return null
      }
    }
    return (
      <>
        {nodeInfo.node?.length > 0 &&
          <Nodes {...props} >
            {nodeInfo.node?.map?.((nodeItem, i) => (
              <NodesLoopRender {...nodeInfo} {...nodeItem} key={i} />
            ))}
          </Nodes>
        }
        {nodeInfo.node?.length <= 0 &&
          <Nodes {...nodeInfo} />
        }
      </>
    )
  }
}

export class RenderNodes extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.onTriggerDone()
    }, 1000);
  }
  onTriggerDone = (attr) => {
    this.doScrollAnimateTimeLine()
    this.doScrollAnimation()
    this.doScrollNavbar()
  }

  onClickTrigger = (attr, e) => {
    const onClickCommannds = attr.media["fox-onclick"];
    if (onClickCommannds) {
      if (onClickCommannds == "close_modal_window") {
        this.closeModalWindow(attr)
      } else if (onClickCommannds == "copy_value") {
        this.copyValue(attr)
      } else if (onClickCommannds == "whatsapp") {
        this.redirectWhatsapp(attr, e)
      } else if (onClickCommannds == "instagram") {
        this.redirectInstagram(attr, e)
      } else if (onClickCommannds == "popup") {
        this.openPopup(attr)
      } else if (onClickCommannds == "gotopage") {
        this.goToPage(attr, e)
      }
    }
  }

  goToPage = (attr, e) => {
    if (attr.media["fox-target"]) {
      const mark = `[fox-data=${attr.media["fox-target"]}]`
      const target = document.querySelector(mark);
      if (target) {
        document.querySelector("body").classList.add("active-scroll")
        target.scrollIntoView({
          behavior: 'smooth'
        });
        this.setState({ isPlay: new Date().getTime() })
      }
    }
  }

  copyValue = (attr) => {
    navigator.clipboard.writeText(attr.media["fox-value"]).then(function () {
      console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  redirectWhatsapp = async (attr, e) => {
    const message = await WhatsappTheme(attr, e);
    const link = `https://wa.me/${WA_NUMBER}?text=${encodeURIComponent(message)}`;
    window.open(link, '_blank');
  }

  redirectInstagram = async (attr, e) => {
    const link = `https://instagram.com/ulamulem`;
    window.open(link, '_blank');
  }

  openPopup = (attr) => {
    const key = attr.media["fox-target"];
    if (key) {
      const targetEl = document.querySelector(`[fox-key="${key}"]`);
      if (targetEl) {
        if (targetEl.className.match(/show/g)) {
          targetEl.classList.remove("show")
        } else {
          targetEl.classList.add("show")
        }
      }
    }
  }

  closeModalWindow = (attr) => {
    const modalWindow = document.querySelector(`[fox-target="${attr.media["fox-target"]}"]`);
    if (modalWindow) {
      const animChild = modalWindow.querySelector('[data-animation-type]')
      if (animChild) {
        animChild.classList?.add?.("slideInDown")
        animChild?.classList?.add?.("reverse")
        animChild.parentElement.classList.add("transparent_background")
      }
      modalWindow.children[0]?.classList?.add?.("slideInDown")
      modalWindow.children[0]?.classList?.add?.("reverse")
      document.querySelectorAll(".dialog-prevent-scroll").forEach((item) => item.classList.remove("dialog-prevent-scroll"))
      // modalWindow.classList
      setTimeout(() => {
        modalWindow.classList.add("hide_block")
        this.setState({ isPlay: new Date().getTime() })
        const addclass = document.querySelectorAll("[fox-addclass]");
        if (addclass.length > 0) {
          addclass.forEach((item) => {
            const className = item.getAttribute("fox-addclass")
            item.classList.add(className)
          })
        }

        const btnActionClick = modalWindow.querySelector(`[key-id=${attr.id}]`)
        if (btnActionClick) {
          if (btnActionClick.getAttribute("demo") != null) {
            setTimeout(() => {
              this.doAutoScrollTest()
            }, 1000);
          }
        }

      }, 1500);
    }
  }

  rundomAnim = () => {
    const anim = ["fadeInUp", "zoomIn"]
    const index = Math.floor(Math.random() * anim.length);
    return anim[index]
  }

  doScrollAnimation = () => {
    let targetAnimated = []
    window.document.body.addEventListener("scroll", (e) => {
      if (targetAnimated.length < 1) {
        targetAnimated = document.querySelectorAll('.animated, .react-photo-album--photo, .animated_hide');
        targetAnimated.forEach((item, i) => {
          if (!item.className?.match?.(/animated_hide/)) {
            item.classList.add("animated_hide")
          }
          if (!item.className?.match?.(/aos-animate/)) {
            item.classList.remove("aos-animate")
          }
          item.classList.remove("aos-animate")
          const animClassName = item.getAttribute("anim-class-name") || `animated ${this.rundomAnim()}`;
          const animClassArr = animClassName.split(" ");
          animClassArr.push("animated")
          animClassArr.forEach((itemClassName) => {
            item.classList.remove(itemClassName);
          })
        })
      }
      // const triggerButton = window.innerHeight / 5 * 4;
      const triggerButton = window.screen.height - (window.screen.height / 3);
      for (let i = 0; i < targetAnimated.length; i++) {
        const el = targetAnimated[i];
        const animClassName = el.getAttribute("anim-class-name") || "animated zoomIn"
        const animClassArr = animClassName.split(" ");
        animClassArr.push("animated")
        // if( (el?.getBoundingClientRect?.()?.top < triggerButton && !el.className?.match?.(animClassName) )  ){
        //   animClassArr.forEach((itemClassName) => {
        //     el.classList.add(itemClassName)
        //   })
        // }
        // else if(el?.getBoundingClientRect?.()?.top > (triggerButton + 300) && el.className?.match?.(animClassName)){
        //   animClassArr.forEach((itemClassName) => {
        //     el.classList.remove(itemClassName);
        //   })
        // }

        if (el?.getBoundingClientRect?.()?.y - (window.screen.availHeight - 30) < 0) {
          animClassArr.forEach((itemClassName) => {
            el.classList.add(itemClassName)
          })
        } else {
          animClassArr.forEach((itemClassName) => {
            el.classList.remove(itemClassName);
          })
        }
      }
    })
  }

  doScrollNavbar = () => {
    let targetElement = [];
    const targetNavBar = document.querySelectorAll(".navbar_icon");
    window.document.body.addEventListener("scroll", (e) => {
      if (targetElement.length < 1) {
        targetElement = document.querySelectorAll('[fox-key]');
        if (targetElement.length < 1) {
          targetElement = document.querySelectorAll('[fox-pages]');
          if (targetElement.length < 1) {
            targetElement = document.querySelectorAll('[fox-page]');
          }
        }
      }
      const triggerButton = window.screen.height - (window.screen.height / 3);
      for (let i = 0; i < targetElement.length; i++) {
        const el = targetElement[i];
        const target = el.getAttribute("fox-key") || el.getAttribute("fox-pages") || el.getAttribute("fox-page")
        if (el?.getBoundingClientRect?.()?.top < triggerButton) {
          targetNavBar.forEach((item, keyId) => {
            if (item.getAttribute("id")?.toLowerCase() == target?.toLowerCase()) {
              item.classList.add("active")
            } else {
              item.classList.remove("active")
            }
          })
        }
      }
    })
  }


  doScrollAnimateTimeLine = (e) => {
    const timeline = document.querySelector('.pp-timeline, .js-timeline_line');
    const box = document.querySelector(".ag-timeline_list")
    let gap = 0;
    if (box) {
      if (box.children) {
        const newLengt = 100 / box.children.length;
        gap = box.children[box.children.length - 1]?.getBoundingClientRect?.()?.height;
        timeline.style.height = `${100 - newLengt}%`
      }
    }
    window.document.body.addEventListener("scroll", (e) => {
      const animClassName = "pp-timeline-item-active"
      if (timeline) {
        const line = timeline.querySelector('.pp-timeline-connector-inner, .js-timeline_line-progress');
        if (line?.parentElement) {

          const items = document.querySelectorAll('.pp-timeline-item');
          items.forEach((item) => {
            item.classList.remove(animClassName);
          })
          const parentHeight = line.parentElement.offsetHeight;
          let newHeight = ((line?.parentElement?.getBoundingClientRect?.()?.height + gap) - line?.parentElement?.getBoundingClientRect?.()?.bottom + gap) * 2;
          if (newHeight < 0) {
            newHeight = 0
          } else if (newHeight >= parentHeight) {
            newHeight = parentHeight
          }
          line.style.height = `${(newHeight)}px`;
          if (newHeight > 0) {
            const deviderHeight = newHeight;
            for (let i = 0; i < items.length; i++) {
              const el = items[i];
              if ((el?.getBoundingClientRect?.()?.height / 1.05) * (i + 1) < deviderHeight) {
                el.classList.add(animClassName)
              } else {
                el.classList.remove(animClassName);
              }
            }
          }
        }
      }
    })
  }




  setBlurry = (callback) => {
    const regex = new RegExp("putri dari|putra dari|desa", "gi")
    const regexCardNumber = /^\d{4} \d{4} \d{4} \d{3}$/
    const elements = document.querySelectorAll("[text]")
    const data = Array.from(elements).filter(element => {
      return regex.test(element.textContent) || regexCardNumber.test(element.textContent)
    });
    data.forEach(element => {
      element.style.filter = 'blur(4px)';
    });

    callback()

  }

  smoothScrollToBottom(duration = 1000) {
    const start = window.scrollY || window.pageYOffset; // Get current scroll position
    const end = document.body.scrollHeight + 1000; // Target scroll position (bottom of the page)
    const change = end - start; // Distance to scroll
    const startTime = performance.now(); // Track the start time for the animation

    // Function to animate the scroll
    function animateScroll(currentTime) {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      const currentScroll = start + change * progress;

      window.document.body.scrollTo(0, currentScroll);
      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    }

    requestAnimationFrame(animateScroll);
  }

  doAutoScrollTest = () => {
    this.setBlurry(() => {
      this.smoothScrollToBottom(45000)
    })
  }

  render() {
    const { props, state } = this;
    return (
      <>
        <NodesLoopRender {...props} {...props.data} onTriggerDone={this.onTriggerDone} onClickTrigger={this.onClickTrigger} />
        {props.isUseMusic &&
          <PlayMusciButton {...props} isPlay={state.isPlay} />
        }
        {props.isUseNavigation &&
          <NavigationBar />
        }
      </>
    )
  }
}



// window.document.body.addEventListener("scroll", (e) => {
//   // console.log(e.getBoundingClientRect());
//   const el = document.querySelector("[key-id='60a8ab7b-686d-41fd-ae0a-d606ebd3dbcb']")
//   console.log(el.getBoundingClientRect().y - window.screen.availHeight );
// })