import { useCallback } from "react";
import Particles from "react-tsparticles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import configPart from './snow.json'

import './index.scss'
import { GenerateAtribute } from "../../@utils/generate-atribute";

export const ParticleJS = (props) => {
  const particlesInit = useCallback(async engine => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    setInterval(() => {
      container.canvas.element.style.position = "absolute !important"
    });
    // console.log(container.canvas.element.style);
    // await console.log(container );
  }, []);

  const getElementConfig = (props) => {
    const res = GenerateAtribute(props);
    return res
  }

  return (
    <div className="particles" {...getElementConfig(props)}>
      <Particles
        style={{ position: 'absolute', width: '100%', height: '100%' }}
        id={props.id}
        init={particlesInit}
        loaded={particlesLoaded}
        options={{ ...configPart, fullScreen: false }}
      />
    </div>
  );
};