import { connect } from "react-redux";
import { StoragesSystem } from "../../@main-module/actions-manager/actions-data";
import { withRouter } from "../../@hoc/with-router";
import RenderPage from '../../@theme-app/nofyta-dan-hendrick/pages/home'


// class HomePage extends RenderPage {}

const mapStateToProps = (state) => ({
  StoragesSystem: state.StoragesSystem,
});
const mapDispatchToProps = (dispatch) => ({
  setStorage: (data = { data: null, type: 'yourkey' }) =>
    dispatch(new StoragesSystem().setStorage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RenderPage, {
  isRestriected: false,
}));
