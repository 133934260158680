export const OwnIconVector = {
  Download: require('./download.json'),
  CardSend: require('./card-send.json'),
  Search: require('./search.json'),
  Edit: require('./edit.json'),
  Image: require('./image.json'),
  Notify: require('./notify.json'),
  World: require('./world.json'),
  User: require('./user.json'),
  Feeds: require('./feeds.json'),
  EyeOpen: require('./eye-open.json'),
  EyeClosed: require('./eye-closed.json'),
  Delete: require('./delete.json'),
  Comment: require('./comment.json'),
  Share: require('./share.json'),
  NewEpisode: require('./new-episode.json'),
  Back: require('./back.json'),
  BackFlat: require('./back-flat.json'),
  NextFlat: require('./next-flat.json'),
  Alert: require('./alert.json'),
  Node: require('./node.json'),
  Close: require('./close.json'),
  Styles: require('./styles.json'),
  PlusCircle: require('./plus-circle.json'),
  ColomDouble: require('./colom-double.json'),
  ColomLeft: require('./colom-left.json'),
  BooksActive: require('./books-active.json'),
  Books: require('./books.json'),
  HeartActive: require('./heart-active.json'),
  Heart: require('./heart.json'),
  Love: require('./heart.json'),
  ColomRight: require('./colom-right.json'),
  Editable: require('./editable.json'),
  ColomThird: require('./colom-third.json'),
  ArrowDrop: require('./arrow-drop.json'),
  CheckMark: require('./check-mark.json'),
  Duplicate: require('./duplicate.json'),
  LoaderCircle: require('./loader-circle.json'),
  // Csv: require('./csv.json'),
  // Ppt: require('./ppt.json'),
  // Doc: require('./doc.json'),
  // Xls: require('./xls.json'),
  // Pdf: require('./pdf.json'),
  // File: require('./file.json'),
  Folder: require('./folder.json'),
  MusicPlay: require('./music-play.json'),
  Instagram: require('./instagram.json'),
  Whatsapp: require('./whatsapp.json'),
  Home: require('./home.json'),
  comments: require('./comments.json'),
  Album: require('./album.json'),
  Date: require('./date.json'),
  Facebook: require('./facebook.json'),
  Map: require('./map.json'),
  Video: require('./video.json'),
  Wifi: require('./wifi.json'),
  Time: require('./time.json'),
  Countdown: require('./countdown.json'),
  Food: require('./food.json'),
  Wedding: require('./wedding.json'),
  Mail: require('./mail.json'),
  Gift: require('./gift.json'),
  Copy: require('./duplicate.json'),
}